import React from 'react'
import Heading from '../../components/typograph/Heading'
import Layout from '../../components/layout/Layout'
import PageContainer from '../../components/layout/PageContainer'
import Hero from '../../components/hero/Hero'
import IlustratedContent from '../../components/ilustratedParagraph/IlustratedContent'
import styled from 'styled-components'
import {
  LIGHT_GRAY,
  GRAY,
  BLUE,
  DARK_GREEN,
  WHITE,
  ACQUA,
  TEXT,
  ORANGE,
} from '../../constants/Colors'

import coursesImage from '../../images/Banner-Cursos-Desk.jpg'
import coursesImageMobile from '../../images/Banner-Cursos-Mobile.jpg'
import cursoClinicaAvancada from '../../images/curso-clinavancada-SCS.png'
import LinkButton from '../../components/buttons/LinkButton'
import GatsbyLink from 'gatsby-link'
import { CommonMeta } from '.'

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
  margin-bottom: 64px;
  margin-top: 64px;

  & .content {
    margin-left: 64px;
  }

  @media (max-width: 799px) {
    flex-wrap: wrap;
    justify-content: center;

    & .noMobo {
      display: none;
    }

    & .content {
      margin-left: 0;
    }
  }
`

const HomeopatiaParaVeterinarios = () => (
  <Layout
    title="Homeopatia Veterinária: Clínica Avançada- HDScience"
    meta={CommonMeta(
      'cursos-e-minicursos/homeopatia-para-veterinarios',
      'Homeopatia Veterinária: Clínica Avançada - HDScience'
    )}
  >
    <Hero image={coursesImage} mobileImage={coursesImageMobile}>
      <Heading
        type="h1"
        style={{ color: '#fff', textAlign: 'center', paddingTop: 30 }}
      >
        CURSOS &<br />
        MINI-CURSOS
      </Heading>
    </Hero>
    <PageContainer style={{ marginBottom: 40 }}>
      <TextContainer>
        <div style={{ padding: 16 }}>
          <IlustratedContent
            image={cursoClinicaAvancada}
            secondary={true}
            style={{
              borderRadius: '20px',
              border: `1px solid ${GRAY}`,
              maxWidth: '240px',
              padding: 20,
              backgroundColor: BLUE,
              color: DARK_GREEN,
            }}
          >
            <Heading type="h5" style={{ margin: 0 }} color={DARK_GREEN}>
              Homeopatia Veterinária: Clínica Avançada
            </Heading>
              12 meses | Online
            <br />
            <br /> <b>Público: </b> Veterinários Homeopatas.
            <br /> <b>Unidade: </b> São Caetano <br />
            <div
              style={{
                marginTop: 10,
                backgroundColor: WHITE,
                width: '278px',
                marginLeft: '-20px',
                border: `1px solid ${LIGHT_GRAY}`,
                padding: '26px 0',
              }}
            >
              <LinkButton
              style={{
                marginTop: 0,
                width: 290,
                marginLeft: -10,
                display: 'block',
                padding: '5px 5px',
                lineHeight: '32px',
              }}
              to="https://api.whatsapp.com/send?phone=5511991760079"
              target="_blank"
              >
                QUERO FAZER O CURSO
              </LinkButton>
            </div>
          </IlustratedContent>
        </div>
        <div style={{ color: TEXT }} className="content">
          <Heading
            type="h3"
            style={{ margin: 0 }}
            color={DARK_GREEN}
            className="noMobo"
          >
           Homeopatia Veterinária: Clínica Avançada
          </Heading>
          <Heading type="h4" style={{ margin: '32px 0 16px 0' }} color={ACQUA}>
            Professores
          </Heading>
          <GatsbyLink
            to="/profissionais/"
            style={{
              textDecoration: 'none',
              color: ORANGE,
              fontWeight: 'bold',
            }}
          >
            Profª Dra. Cidéli Coelho
          </GatsbyLink>,
          {' '}
          <GatsbyLink
            to="/profissionais/"
            style={{
              textDecoration: 'none',
              color: ORANGE,
              fontWeight: 'bold',
            }}
          >
            Dr. Adalberto von Ancken
          </GatsbyLink>{' '}e{' '}
          Dra. Daniela Lopes
          <br />
          <Heading type="h4" style={{ margin: '32px 0 16px 0' }} color={ACQUA}>
            Início
          </Heading>
          Turmas formadas em fevereiro
          <br />
          <Heading type="h4" style={{ margin: '32px 0 16px 0' }} color={ACQUA}>
            Certificação
          </Heading>
            Ebramec / HD Science
          <br />
          <Heading type="h4" style={{ margin: '32px 0 16px 0' }} color={ACQUA}>
            Pré-Requisito
          </Heading>
            Certificado em curso de homeopatia com 800h; ou prova (nota mínima 7,0) e entrevista.
          <br />
          <Heading type="h4" style={{ margin: '32px 0 16px 0' }} color={ACQUA}>
            Carga Horária & Duração Total
          </Heading>
          12 meses ou 360 horas divididas em 12 módulos (1 módulo por mês) conforme determinado no conteúdo.
          <br />
          <Heading type="h4" style={{ margin: '32px 0 16px 0' }} color={ACQUA}>
            Contratação
          </Heading>
          12 módulos que podem ser contratados em conjunto (12x de R$ 450,00).
            <br/><br/>
          <b> Alunos Ebramec ou HD Science tem condições especiais, 
            <GatsbyLink 
            style={{
              textDecoration: 'none',
              color: ORANGE,
              fontWeight: 'bold',
            }}
            to="/contato"> fale conosco!</GatsbyLink></b>
          <br />
          <Heading type="h4" style={{ margin: '32px 0 16px 0' }} color={ACQUA}>
            Horário do Curso
          </Heading>
          Um sábado de cada mês* online.
          Material de estudo semanalmente disponibilizado online.
          <br/>
          <br />
          *Datas determinadas no cronograma de cada semestre.
          <Heading type="h4" style={{ margin: '32px 0 16px 0' }} color={ACQUA}>
            Conteúdo
          </Heading>
          <ul style={{ lineHeight: 2 }}>
            <li>Fevereiro: Módulo I - Sistema Renal e Urinário</li>
            <li>Março: Módulo II - Sistema Neurológico</li>
            <li>Abril: Módulo III – Sistema Cardíaco e Respiratório</li>
            <li>Maio: Módulo IV – Doenças Metabólicas e Hepáticas</li>
            <li>Junho: Módulo V - Sistema Osteo Articular</li>
            <li>Julho: Módulo VI – Dermatologia</li>
            <li>Agosto: Módulo VII – Neoplasias</li>
            <li>Setembro: Módulo VIII – Otorrinolaringologia</li>
            <li>Outubro: Módulo IX - Sistema Imune e Doenças Auto Imunes</li>
            <li>Novembro: Módulo X - Sistema Reprodutivo e Hormonal</li>
            <li>Dezembro: Módulo XI - Apresentação de Caso Clínico</li>
            <li>Janeiro: Módulo XII - Injetáveis e Viscum</li>
          </ul>
        </div>
      </TextContainer>
    </PageContainer>
  </Layout>
)

export default HomeopatiaParaVeterinarios
